<template>
    <div class="clone h-8 w-8 rounded-full  bg-gray-300 flex items-center justify-center cursor-pointer" @click="$emit('handleCloneField',row )" v-tippy content="Clone" >
        <font-awesome-icon icon="clone" />
    </div>
</template>

<script>
export default {
    name: "clone",
    props: {
        row: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style lang="scss" scoped>
// * {
//     box-sizing: border-box;
// }
</style>
